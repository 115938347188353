<template>
  <div>
    <exams-filter
      @getData="setDataFilter($event)"
      @clearData="clearData"
      :id="id"
    />
    <data-table
      @updatePage="getData($event)"
      :headers="data.headers"
      :data="data.data"
      :meta="data.meta"
      :loader="loader"
      :showable="showAble"
    />
  </div>
</template>
<script>
import examsFilter from "./examsFilter.vue";
export default {
  props: ["data", "loader", "id"],
  components: {
    examsFilter,
  },
  data() {
    return {
      filterData: {},
    };
  },
  methods: {
    getData(page) {
      Object.assign(this.filterData, { page: page });
      this.$emit("getData", this.filterData);
    },
    setDataFilter(data) {
      Object.assign(data, { from_starting_date: data.starting_date });
      Object.assign(data, { to_starting_date: data.starting_date });
      Object.assign(data, { from_finishing_date: data.finishing_date });
      Object.assign(data, { to_finishing_date: data.finishing_date });
      delete data.starting_date;
      delete data.finishing_date;

      this.filterData = data;
      this.getData(1);
    },
    clearData() {
      this.filterData = {};
      this.getData({ page: 1, edu_class_id: this.id });
    },
    showAble(obj) {
      this.$router.push(`/exam/${obj.id}`);
    },
  },
};
</script>
