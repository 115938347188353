<template>
  <v-row>
    <v-col lg="2">
      <v-text-field
        v-model="data.name"
        :label="$t('serach-by-name')"
        outlined
      ></v-text-field>
    </v-col>
    <v-col lg="2">
      <auto-complete
        ref="autoComplete"
        @getData="data.subject_id = $event"
        :label="'serach-by-subject'"
        :Store="Subject"
        :isObject="true"
      ></auto-complete>
    </v-col>
    <v-col lg="2" cols="12" sm="6" md="4">
      <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        :return-value.sync="data.starting_date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="data.starting_date"
            :label="$t('starting_date')"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            outlined
          ></v-text-field>
        </template>
        <v-date-picker
          :locale="$i18n.locale"
          v-model="data.starting_date"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu1 = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu1.save(data.starting_date)"
          >
            {{ $t("agree") }}
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col lg="2" cols="12" sm="6" md="4">
      <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="false"
        :return-value.sync="data.finishing_date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="data.finishing_date"
            :label="$t('finishing_date')"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            outlined
          ></v-text-field>
        </template>
        <v-date-picker
          :locale="$i18n.locale"
          v-model="data.finishing_date"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu2 = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu2.save(data.finishing_date)"
          >
            {{ $t("agree") }}
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col lg="2">
      <v-select
        v-model="data.status"
        :label="$t('serach-by-status')"
        :items="types"
        item-value="value"
        item-text="name"
        outlined
      ></v-select>
    </v-col>
    <v-col lg="2">
      <v-icon
        @click="$emit('getData', data)"
        color="primary"
        class="ma-2"
        x-large
        >mdi-magnify</v-icon
      >
      <v-icon @click="clearData" color="primary" class="ma-2" x-large
        >mdi-close</v-icon
      >
    </v-col>
  </v-row>
</template>
<script>
import { Subject } from "@/store/teacher/subjects";
export default {
  props: ["id"],
  data() {
    return {
      Subject,
      data: {},
      searchTerm: "",
      menu1: false,
      menu2: false,
      page: 1,
    };
  },
  methods: {
    clearData() {
      this.data = {};
      this.$refs.autoComplete.reset();
      this.$emit("clearData");
    },
    onIntersect() {},
  },
  computed: {
    types() {
      return [
        {
          value: "new",
          name: this.$t("new"),
        },
        {
          value: "done",
          name: this.$t("done"),
        },
      ];
    },
    subjectsItems() {
      return Subject.data;
    },
    stopInfinityScroll() {
      return Subject.data.length != 0;
    },
  },
  mounted() {
    Subject.get({ page: 1, edu_class_id: this.id });
  },
  watch: {
    searchTerm(val) {
      if (Subject.getState.loading) return;
      setTimeout(() => {
        this.page = 1;
        Subject.get({ page: this.page, name: val });
      }, 500);
    },
  },
};
</script>
